import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}images/giftcards/gift-card-banner.jpg`;
const febContestBanner = `${settings.IMAGES_BASE_URL}images/contests/indy-giveaway.jpg`;

const Contest = () => {
  const getOfficialRules = () => {
    const today = new Date();
    const febContest =
      today >= new Date(2024,1,12) &&
      today < new Date(2024,1,26,17,0);
    if (febContest) {
      return (
        <section className="performance-center-contest-rules">
          <p>
            <span className="bold">OFFICIAL RULES</span>
            <br />
            <span className="bold">BMW PERFORMANCE CENTER CCA INDY PROMO</span>
            <br />
            <br />
          </p>
          <p>
            NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. 
            2 Free Full-Day M Experience Seats in Indianapolis, Indiana contest. (the “Promotion”) begins at 
            10:00 a.m. Eastern Time (“ET”) on February 12, 2024, and ends at 5:00 p.m. ET on February 26, 2024 
            (“Promotion Period”).
            <br />
            <br />
            ELIGIBILITY: Promotion OPEN ONLY TO LEGAL RESIDENTS OF ALL 50 STATES WITH A VALID U.S. 
            DRIVERS LICENSE. Employees, contract workers and lease employees (and their immediate families 
            (parent, child, spouse or sibling and their respective spouses, regardless of where they reside) 
            and those living in their same households, whether or not related) of the, BMW of North America, 
            LLC (“Sponsor”), and their respective parents, affiliates, subsidiaries and advertising and 
            promotion agencies are not eligible to enter or win. By participating, entrants agree to be 
            bound by these Official Rules and the decisions of the judges and/or Sponsor, which are binding 
            and final on matters relating to this promotion. Promotion is subject to all applicable federal, 
            state and local laws.
            <br />
            <br />
            ENTRY PERIODS AND HOW TO ENTER: To register, during the Promotion Period, must be a CCA Members 
            like @BMWPerformanceDrivingschools Instagram page, Like the contest Instagram post, post an image 
            of you attending an event at one of our 3 locations: INDY, SC or CA and use both tags 
            @BMWPerformanceDrivingSchools #MOREBMWPDS and tag 3 of your friends in the comments section of the 
            Instagram post using their Instagram handle. Entries generated by script, macro or other automated 
            means and entries by any means which subvert the entry process are void. Limit of one (1) entry per 
            person using only one (1) Instagram account throughout the Promotion Period. Entries received from 
            any person or Instagram account in excess of the above stated limitation will be void. All entries 
            become the sole property of the Sponsor and will not be acknowledged or returned. Opt-in 
            opportunities may be available but are not required and will not increase or decrease your chances 
            of winning. Opting in to receive email and other electronic communications from BMW is not a 
            pre-condition to contest entry.
            <br />
            <br />
            DRAWING: One winner will be selected on Friday, March 1, 2024. The winners will be selected by 
            random drawing among all eligible entries. Actual odds of winning depend upon the number of 
            eligible entries received. Potential winners will be notified by Instagram on or around 
            March 1, 2024. If potential winners decline the prize or a potential winner is not in compliance 
            with these rules, applicable prize will be forfeited and, at Sponsor’s discretion, an alternate 
            winner selected by random drawing among all remaining eligible entries. Potential winner may be 
            required to execute and return an affidavit of eligibility, a liability release and a publicity 
            release on or about March 1, 2024. If such documents are not returned within the specified time 
            period, prize notification is returned as undeliverable, or potential winner will not be in 
            compliance with these rules, prize will be forfeited and, at Sponsor’s discretion, a runner-up may 
            be notified. The prizes will be awarded after receipt of the documentation and verification of 
            eligibility. Sponsor’s decisions are final and binding on all matters relating to this Contest.
            <br />
            <br />
            PRIZES: One prize will be awarded to one winner for the Indianapolis during the contest period. 
            Approximate value of the prize is $5,000 + tax ($2,500 per seat). The prize shall consist of the 
            following, good for the selected winner: 2 Free Full-Day M Experience in Indianapolis. Travel 
            expenses are not included. The winner will be responsible for all travel expenses. Prizes are 
            awarded “as is” with no warranty or guarantee, either express or implied by Sponsor. Winners may 
            not substitute, assign or transfer prize or redeem prize for cash, but Sponsor reserves the right, 
            at its sole discretion, to substitute prize (or portion thereof) with one of comparable or greater 
            value. Winners are responsible for all applicable federal, state and local taxes, if any, as well 
            as any other costs and expenses associated with prize acceptance and use not specified herein as 
            being provided. All prize details are at Sponsor’s sole discretion and subject to change.
            <br />
            <br />
            GENERAL CONDITIONS: Released Parties (as defined below) are not responsible for lost, late, 
            incomplete, inaccurate, stolen, misdirected, undelivered, garbled, illegible or postage-due mail, 
            entries or email; or for lost, interrupted or unavailable network, server Internet Service 
            Provider (ISP), website, or other connections, availability or accessibility or miscommunications 
            or failed computer, satellite, telephone or cable transmissions, lines, or technical failure or 
            jumbled, scrambled, delayed, or misdirected transmissions or computer hardware or software 
            malfunctions, failures or difficulties, or other errors or difficulties of any kind whether human, 
            mechanical, electronic, computer, network, typographical, printing or otherwise relating to or in 
            connection with the promotion, including, without limitation, errors or difficulties which may 
            occur in connection with the administration of the promotion, the processing of entries, the 
            announcement of the prizes or in any promotion-related materials. Released Parties are also not 
            responsible for any incorrect or inaccurate information, whether caused by site users, (seem accurate) 
            tampering, hacking, or by any equipment or programming associated with or utilized in the promotion. 
            Released Parties are not responsible for injury or damage to participants’ or to any other person’s 
            computer related to or resulting from participating in this promotion or downloading materials from or 
            use of the web site. Persons who tamper with or abuse any aspect of the promotion or website or who are 
            in violation of these Official Rules, as solely determined by Sponsor, will be disqualified and all associated 
            entries will be void. Should any portion of the promotion be, in Sponsor’s sole opinion, compromised by 
            virus, worms, bugs, non-authorized human intervention or other causes which, in the sole opinion of the 
            Sponsor, corrupt or impair the administration, security, fairness or proper play, or submission of entries, 
            Sponsor reserves the right at its sole discretion to suspend, modify or terminate the promotion and, if 
            terminated, at its discretion, select the potential winners from all eligible, non-suspect entries 
            received prior to action taken or as otherwise deemed fair and appropriate by Sponsor. Entrants, by 
            participating, agree that Sponsor and their respective parents, affiliates, subsidiaries and advertising 
            and promotion agencies and all of their respective officers, directors, employees, representatives and 
            agents (collectively, “Released Parties”) will have no liability whatsoever for, and shall be held 
            harmless by entrants against, any liability, for any injuries, losses or damages of any kind, including 
            death, to persons, or property resulting in whole or in part, directly or indirectly, from acceptance, 
            possession, misuse or use of any prize or participation in this promotion. Except where legally 
            prohibited, entry into this Contest and/or winner’s acceptance of prize constitutes permission for 
            Sponsor and its designees to use his/her name, address (city and state), photograph, voice and/or other 
            likeness, biographical information and prize information for advertising, trade and promotional 
            purposes without further compensation, in all media now known or hereafter discovered, worldwide in 
            perpetuity, without notice or review or approval. In the event of a dispute regarding entries received 
            from multiple users having the same Instagram account, the authorized subscriber of the Instagram account 
            at the time of entry will be deemed to be the entrant and must comply with these rules. Authorized 
            account subscriber is the natural person who is assigned the Instagram account by Instagram, or other 
            organization responsible for assigning Instagram accounts.
            <br />
            <br />
            SPONSOR: BMW Performance Center 155 SC-101, Greer, SC 29651, 86-050 Jasper Lane, Thermal, CA 92274, 4790 W 16th St, Indianapolis, IN 46222.
          </p>
        </section>
      );
    }
  };
  const getFirstCard = () => {
    const today = new Date();
    const febContest =
      today >= new Date(2024,1,12) &&
      today < new Date(2024,1,26,17,0);
    if (febContest) {
      return (
        <FlexCard
          rowType="row"
          image={febContestBanner}
          imageAlt="WIN A DRIVE AT INDY"
          header="WIN A DRIVE AT INDY"
          subheader="ONE WINNER, TWO PRIZES"
          btnText="Visit our Instagram page"
          btnStyle="btn-blue"
          externalURL="https://www.instagram.com/bmwperformancedrivingschools"
        >
          <p>
            We’re giving away two seats at INDY in spring of 2024 for BMW CCA members.
          </p>
          <p>
          <span className="bold">TO ENTER</span>
          <br />
            1. You must be a BMW CCA member  <br />
            2. Follow @BMWPerformanceDrivingSchools <br />
            3. Post an image of you attending an event at one of our three school locations (INDY, South Carolina or California) and use both tags @BMWPerformanceDrivingSchools #MOREBMWPDS to your Instagram account.<br/>
            4. Like this Instagram post and tag three of your friends in the comments.         
          </p>
          <p>
            Entries close on Monday, 2/26/24. One winner will be selected at random and announced on Friday, 
            3/1/24 on our story. No purchase is necessary. This is not affiliated with Instagram. 
            The winner is responsible for all travel expenses.
          <br />
          <br />
          </p>
        </FlexCard>
      );
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Giveaway"
        description="Get free performance center gear"
      />
      <div className="giveawaypage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Giveaway Top Banner"
        />

        <section className="performance-center">{getFirstCard()}</section>
        {getOfficialRules()}
      </div>
    </Layout>
  );
};

export default Contest;
